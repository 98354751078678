.navbar {
  width: 100%;
  max-width: 100%;
  padding: 0 20px;
  background-color: var(--bg-secondary-dark);
  color: white;
  font-family: 'Roboto', sans-serif;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
}

.branding-logo {
  max-height: 70px;
}

.navbrand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navlinks {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
}

.navlink {
  margin: 0 10px;
  font-size: 1.1em;
  text-decoration: none;
  color: white;
}

.navlink:hover {
  opacity: 80%;
}

.plain-navlink {
  text-decoration: none;
}

.button-primary {
  padding: 10px;
  background-color: var(--teal-secondary);
  font-weight: bold;
  border-radius: 2px;
}

.newPostButton {
  transition: all 0.2s;
  color: white;
  max-width: 200px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(162deg, rgba(112,190,149,1) 39%, rgba(80,132,132,1) 64%);
  background-position: 0% 0%;
  background-size: 200% 200%;
}

.newPostButton:hover {
  transition: all 0.2s;
  background-position: 60% 60%;
}

.username {
  font-size: 1.3em;
}

@media only screen and (max-width: 640px){
    .navbar {
      padding: 10px 10px;
    }

    .branding-logo {
      max-height: 40px;
    }

    .username {
      display: none;
    }

    .navlink {
      margin: 0 5px;
    }
}

@media only screen and (max-width: 416px){
    .newPostButton {
      display: none;
    }
    .navlinks {
      margin-right: 15px;
    }
}

