.card-wrapper {
  width: 100%;
  background-color: var(--bg-primary-dark);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
  margin: 10px 0;
  animation: load-card 400ms linear;
}

@keyframes load-card {
  0% {
    opacity: 0;
    transform: translatey(100vh);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

.post-title {
  color: white;
  padding: 0 20px;
}

.image-container {
  display: flex;
  align-items: center;
  background-color: black;
}

.video-container {
  display: flex;
  background-color: black;
  min-height: 200px;
  max-height: 400px;
  width: 100%;
}

.card-image {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.post-detail-link {
  text-decoration: none;
}

.post-action-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  margin: 0 10px;
  border-top: 2px solid rgba(0, 0, 0, 0.247);
}

.post-action {
  cursor: pointer;
}

.post-main-actions, .post-secondary-actions {
  display: flex;
  align-items: center;
}

.private-post-indicator {
  border-radius: 15px;
  color: rgb(175, 175, 175);
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.private-post-indicator > svg {
  margin-left: 5px;
}