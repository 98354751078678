.upload-drop-container {
  background-color: var(--teal-primary);
  min-width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  align-content: center;
  z-index: 0;
}


@media only screen and (max-width: 825px){
  .upload-drop-container {
    display: none;
  }
}

.upload-image {
  grid-area: 1 / 1 / 2 / 2;
  width: 550px;
  margin-left: -102px;
  margin-top: 140px;
  
  z-index: 10;
}

.upload-target {
  transition: all 0.3s;
  width: 150px;
  height: 150px;
  
  background: none;
  border: 4px dashed rgba(204, 204, 204, 0.671);;
  border-radius: 50%;

  grid-area: 1 / 1 / 2 / 2;
  margin-top: 270px;
  margin-left: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 11;
}

.upload-target-animate {
  transition: all 0.3s;
  width: 415px;
  height: 415px;

  background: none;
  border: 4px dashed rgba(255, 255, 255, 0.671);
  border-radius: 50%;

  grid-area: 1 / 1 / 2 / 2;
  margin-top: 136px;
  margin-left: 34px;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: rotateDropCircle 1s linear infinite, lowerZindex 0.27s linear;

  z-index: 9;
}

.upload-target-animate > p, .upload-target-animate > svg {
  transition: all 0.3s;
  transform: rotate(270deg);
  opacity: 0;
}

.upload-target > p {
  transition: all 0.3s;
  color: rgba(255, 255, 255, 0.671);
  font-family: 'Fredoka One', cursive;
  z-index: 11;
}

@keyframes lowerZindex {
  0% {
    z-index: 11;
  }
  100% {
    z-index: 9;
  }
}

@keyframes rotateDropCircle {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(20deg)
  }
}