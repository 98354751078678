.comment-pfp {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.comment-poster-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.comment-text {
  word-wrap: break-word;
}

.comment {
  padding-top: 5px;
  border-bottom: 2px solid var(--bg-primary-dark);
}

.no-style-link {
  text-decoration: none;
  color: white;
}