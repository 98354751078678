.profile-page {
  background-color: var(--bg-primary-dark);
  padding-top: 100px;
  padding-bottom: 30px;
  min-height: 100vh;
}

.profile {
  margin: auto;
  width: 75%;
}

.profile-top {
  width: 100%;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.514);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.profile-bottom {
  max-width: 100%;
  background-color: var(--bg-secondary-dark);
  padding: 30px;
}

.pf-username {
  margin: 10px;
  font-size: 40px;
  color: white;
}

.pfp {
  margin-top: -100px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-pfp-button {
  opacity: 0;
  text-align: center;
  position: absolute;
  color: white;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px;
  border: 2px solid white;
  border-radius: 10px;
}

.pfp-edit:hover > .change-pfp-button {
  transition: all 0.3s ease-out;
  opacity: 1;
}

.pfp-edit:hover > div {
  transition: all 0.3s ease-out;
  filter:brightness(10%);
}

.pfp-edit > div {
  transition: all 0.3s ease-out;
}

.pfp-initial {
  color: rgb(255, 255, 255);
  user-select: none;
  font-size: 40px;
  margin: 0;
}

.pfp-container {
  display: flex;
  margin-left: 10px;
}

.divide-line {
  width: 100%;
  height: 5px;
  background-color: rgba(124, 124, 124, 0.397);
}

.user-profile-posts-title {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile-posts-title > p {
  color: white;
  font-size: 1.2em;
  margin: 0;
}

.profile-new-post {
  color: white;
  border: none;
  margin: 0;
  cursor: pointer;
  transition: all 0.2s;
  background: linear-gradient(162deg, rgba(112,190,149,1) 39%, rgba(80,132,132,1) 64%);
  background-position: 0% 0%;
  background-size: 200% 200%;
  font-size: 1.1em;
}

.profile-new-post:hover {
  transition: all 0.2s;
  background-position: 60% 60%;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info-item {
  text-align: right;
  color: white;

}

.user-info-item > span {
  font-weight: bold;
  color: var(--teal-secondary)
}

@media only screen and (max-width: 900px){
  .profile {
    margin: auto;
    width: 90%;
  }
}

@media only screen and (max-width: 600px){
  .profile {
    margin: auto;
    width: 98%;
  }
}