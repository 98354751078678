.profilePicContainer {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.pfp-large {
  width: 60px;
  height: 60px;
}

.pfp-med {
  width: 50px;
  height: 50px;
}

.pfp-small {
  width: 30px;
  height: 30px;
}

.pfp-xlarge {
  width: 130px;
  height: 130px;
}

.profilePic {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.alt-img {
  width: 100%;
  padding: 50% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--teal-secondary);
  border-radius: 50%;

}

.alt-img > p {
  margin: 0;
  height: 0;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alt-xlarge {
  background-color: var(--teal-primary);
}

.alt-xlarge > p {
  line-height: 0.1em;
  font-size: 2em;
}

.alt-med > p {
  line-height: 0.1em;
  font-size: 1.2em;
}

.alt-large > p {
  line-height: 0.1em;
  font-size: 1.3em;
}

.alt-small > p {
  line-height: 0.1em;
  font-size: 1.0em;
}