.view-post-page {
  background-color: var(--bg-primary-dark);
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 20px;
}

.post-card {
  max-width: 800px;
  margin: auto;
  width: 100%;
  min-height: 500px;
  background-color: rgba(128, 128, 128, 0);
  display: flex;
  flex-direction: column;
}

.post-image-wrapper {
  width: 100%;
  max-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.562);
  overflow: hidden;
}

.post-image-wrapper > img {
  max-height: 50vh;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.post-content {
  font-family: 'Roboto', sans-serif;
  color: white;
  background-color: var(--bg-secondary-dark);
  border: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 10px 10px;
}

.post-content-header {
  font-size: 1.3em;
  margin: 0;
}

.post-content-date {
  margin: 0;
}

.post-header {
  padding: 20px 20px 15px 20px;
  max-width: 100%;
  background-color: var(--bg-secondary-dark);
  border-radius: 10px 10px 0 0;
}

.post-hidden-tip {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid var(--bg-primary-dark)
}

.post-hidden-tip > .post-hidden-text {
  color: white;
  cursor: pointer;
  padding: 10px 0;
}

.post-hidden-tip > .post-hidden-text:hover ~ .hidden-text-tooltip {
  transition: 0.2s all;
  display: block;
  opacity: 1;
}

.hidden-text-tooltip {
  transition: 0.2s all;
  opacity: 0;
  position: absolute;
  color: rgb(255, 255, 255);
  background-color: var(--bg-primary-dark);
  padding: 10px;
  max-width: 200px;
  border-radius: 5px;
  margin-top: 160px;
  margin-left: 215px;
}

.hidden-text-tooltip > span {
  color: var(--teal-secondary);
}

.post-hidden-tip > div > span {
  color: var(--teal-secondary);
}

.share-with-public {
  transition: all 0.2s;
  color: white;
  max-width: 200px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(162deg, rgba(112,190,149,1) 39%, rgba(80,132,132,1) 64%);
  background-position: 0% 0%;
  background-size: 200% 200%;

}

.share-with-public:hover {
  transition: all 0.2s;
  background-position: 60% 60%;
}

.poster-info {
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.post-user-profile {
  display: flex;
  color: white;
  text-decoration: none;
  align-items: center;
}

.button-primary {
  padding: 10px;
  width: auto;
  background-color: var(--teal-secondary);
  font-weight: bold;
  border-radius: 2px;
  margin-right: 10px;
}

.post-header-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}