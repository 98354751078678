.comment-title {
border-left: 2px solid var(--bg-primary-dark);
padding-left: 10px;
}

.comment-scroll-container {
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column-reverse;
}

.comment-list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}