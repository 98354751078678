.landing-page {
  background-color: var(--landing-bg-dark);
  padding: 100px 0 50px 0;
  min-height: 100vh;
}

.post-list {
  width: 75%;
  margin: auto;  
}

@media only screen and (max-width: 1400px){
    .post-list {
      width: 95%;
    }
}

.caughtup-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.caughtup-img {
  width: 350px;
}

.landing-error-text {
  color: tomato;
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
}