.post-action {
  display: flex;
  justify-content: space-between;
  margin-right: 7px;
  max-width: 60px;
}

.post-action > p {
  margin: 7px 5px;
  color: white;
}