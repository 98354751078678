.error-404 {
  height: 100vh;
  background-color: var(--bg-primary-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.error-404 > img {
  min-width: 300px;
  width: 30%;
}

.title-404 {
  margin: 5px;
}

.desc-404 {
  margin: 10px 30px 30px 30px;
  font-size: 1.1em;
  text-align: center;
}