.dropdown-menu {
  padding: 5px;
  color: rgb(226, 226, 226);
  background: none;
  border: none;
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  max-width: 250px;
}

.dropdown-border {
  border-bottom: 2px solid rgb(226, 226, 226);
}

.dropdown-menu > option {
  color: black;
  background-color: white;
}

.dropdown-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dropdown-title {
  font-size: 1.2em;
  color: white;
  margin:0 0 0 20px;
}