.upload-page {
  background-color: var(--bg-primary-dark);
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.upload-container {
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
  display: flex;
  height: 500px;
}

.upload-form {
  display: flex;
  flex-direction: column;
  max-width: 40%;
  padding: 40px;
  background-color: var(--bg-secondary-dark);
}

@media only screen and (max-width: 825px){
  .upload-form  {
    max-width: none;
    width: 70%;
  }
  .upload-container {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px){
  .upload-form  {
    max-width: none;
    width: 90%;
  }
}

.upload-title {
  color: white;
  font-family: 'Fredoka One', cursive;
}

.upload-form > form > label {
  color: white;
}