.post-container {
  margin-top: 10px;
  display: grid;
  justify-items: center;
  grid-template-areas: "c1 c2 c3 c4";
  grid-gap: 20px;
  margin-left: 20px;
}

.post-column {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
}

@media only screen and (min-width: 1401px){
    .post-container {
      grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1399){
    .post-container {
      grid-template-columns: repeat(3, 1fr);
      margin-left: 40px;
    }
}

@media only screen and (max-width: 999px){
    .post-container {
      grid-template-columns: repeat(2, 1fr);
      margin-left: 60px;
    }
}

@media only screen and (max-width: 599px){
    .post-container {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0px;
      margin-left: 0;
    }
}