@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

:root {
  --bg-primary-dark: #4d5359;
  --landing-bg-dark: #393e42;
  --bg-secondary-dark: #242f40c5;
  --teal-dark: #23685c;
  --teal-primary: #508484;
  --teal-secondary: #70be95;
  --green-secondary: #97DB4F;
}

/* 79C99E */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
