.formbody {
  display: flex;
  flex-direction: column;
}

.form-field {
  padding: 15px;
  font-size: 1.2em;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 5px;
  margin: 5px 0; 
}

.form-field:focus {
  outline: none;
  padding: 14px;
  border: 2px solid var(--teal-primary);
}

label {
  color: rgb(0, 0, 0);
  margin-top: 20px;
  font-weight: 100;
}

.submit-button {
  margin-top: 20px;
  padding: 20px;
  color: white;
  background-color: var(--teal-primary);
  font-weight: 100;
  font-size: 1em;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  transition: all 0.2s;
}

.submit-button:hover {
  transition: all 0.2s;
  opacity: 0.8;
  box-shadow: none;
}

button:disabled,
button[disabled]{
  background-color: #c3c3c3;
  color: #646464;
}

.error-text {
  word-wrap: break-word;
  color: tomato;
  margin-bottom: 0;
}

.file-field {
  display: none;
}

.file-upload-button {
  padding: 15px;
  font-size: 1.2em;
  border-radius: 5px;
  margin: 5px 0; 
  transition: opacity 0.2s;
  background-color: white;
  cursor: pointer;
  color: gray;
}

.file-upload-button:hover {
  transition: opacity 0.2s;
  opacity: 0.8;
}